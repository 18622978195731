import { Button } from "@mui/material"
import React from "react"
import "./errorPages.css"
import { useNavigate } from "react-router-dom"
import { appImages } from "../../cdnImages"

export const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <div className="_404ErrorContainer">
            <p className="_404ErrorText">
                Ooops!...<br></br> Page not found
            </p>
            <img src={appImages?.["404_error_icon"]} alt="404-Error-Icon" className="_404ErrorImage" />
            <Button variant="contained" color="primary" size="large" onClick={() => navigate("/")}>
                Go Home
            </Button>
        </div>
    )
}
