import { Button } from "@mui/material"
import React from "react"
import "./errorPages.css"
import { useNavigate } from "react-router-dom"
import { appImages } from "../../cdnImages"

export const UnauthorisedAccess = () => {
    const navigate = useNavigate()
    return (
        <div>
            <div className="_404ErrorContainer">
                <p className="_404ErrorText">
                    Ooops!...<br></br> Unauthorised access
                </p>
                <img src={appImages?.["401_error_icon"]} alt="401-Error-Icon" className="_401ErrorImage" />
                <Button variant="contained" color="primary" size="large" onClick={() => navigate("/")}>
                    Go Home
                </Button>
            </div>
        </div>
    )
}
