import { Dialog, DialogContent, Button } from "@mui/material"

import { appImages } from "../../cdnImages"

const FallbackRender = ({ error, resetErrorBoundary }) => {
    return (
        <Dialog open={error.message} onClose={() => {}} maxWidth={"xl"} aria-labelledby="form-dialog-title">
            <DialogContent className="regular_modal_font" style={{ margin: "20px 60px" }}>
                <div className="flex_col align_center gap_15">
                    <img height={"120px"} src={appImages?.error_screen} alt="Error modal" />
                    <div>
                        <p>Something went wrong:</p>
                        <p className="fs_12 text_center error-message">{error.message} </p>
                    </div>
                    <a href="/">
                        <Button variant="contained" color="primary">
                            Home
                        </Button>
                    </a>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default FallbackRender
