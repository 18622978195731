import { isAfter } from "date-fns"
import { useEffect, useState } from "react"
import CryptoJS from "crypto-js"

export function ucfirst(string) {
    if (string)
        return string
            .split(",")
            .map(con => con.charAt(0).toUpperCase() + con.slice(1))
            .join()
    else return ""
}

export function saveItem(key, value) {
    localStorage.setItem(key, value)
}

export function getItem(key) {
    return localStorage.getItem(key) != null ? localStorage.getItem(key) : ""
}

export function saveEncryptedItem(key, data) {
    const secretKey = process.env.REACT_APP_SECRET_KEY || ""
    const encodedString = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString()
    localStorage.setItem(key, encodedString)
}

export function getDecryptedItem(key) {
    const encryptedString = localStorage.getItem(key)
    if (!encryptedString) return null

    const secretKey = process.env.REACT_APP_SECRET_KEY || ""

    try {
        const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey)
        const decodedString = bytes.toString(CryptoJS.enc.Utf8)
        return JSON.parse(decodedString) || {}
    } catch (error) {
        return null
    }
}

export function removeItem(key) {
    localStorage.removeItem(key)
}

export function formatPhoneNumber(value, digit = 2) {
    if (value !== null) {
        let stringValue = value.toString()
        let code = stringValue.substring(0, digit)
        let number = stringValue.substring(digit)
        return `+${code}-${number}`
    } else {
        return value
    }
}
export const formatNumber = (countryCode, phone) => {
    const code = countryCode?.replace("+", "")
    return formatPhoneNumber(phone, code?.length)
}

export function formatParams(data) {
    let paramsArr = []

    for (const key in data) {
        paramsArr.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    }
    return paramsArr.join("&")
}

export const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.src = "https://checkout.razorpay.com/v1/checkout.js"
        script.onload = resolve
        script.onerror = reject
        document.body.appendChild(script)
    })
}

export function RazorPay(
    key,
    amount,
    handlerSuccess,
    prefill,
    notes = {},
    failureFunc = null,
    resetFunc = null,
    orderId = "",
    onDismiss = null
) {
    const options = {
        key: key,
        amount: parseInt(amount) * 1 * 100,
        currency: "INR",
        name: process.env.REACT_APP_COMPANY,
        description: "Payment Through Razorpay.",
        image: getImageURL(process.env.REACT_APP_LOGOS, "sign_in_form"),
        notes: notes,
        order_id: orderId,
        handler: async () =>
            setTimeout(() => {
                handlerSuccess?.()
                resetFunc?.()
            }, 1000),
        prefill: prefill,
        theme: {
            color: "#203594",
        },
        modal: {
            ondismiss: function () {
                document.body.style.overflow = "auto"
                onDismiss?.()
                resetFunc?.()
            },
        },
    }

    if (window.Razorpay) {
        const paymentObject = new window.Razorpay(options)
        paymentObject.on("payment.failed", failureFunc)
        paymentObject.open()
    }
}

export const convertRolestoJS = ({ roles }) => {
    return Array.isArray(roles)
        ? roles?.map(module => {
              const subModule = module?.child?.map(submodule => {
                  const access = submodule?.child?.map(access => {
                      return { name: access?.name, value: access?.value }
                  })
                  return {
                      is_view: submodule?.is_view,
                      name: submodule?.name,
                      child: access,
                  }
              })
              return {
                  is_view: module?.is_view,
                  name: module?.name,
                  child: subModule,
              }
          })
        : []
}

export const getStatusColor = data => {
    let value = data ? data.toLowerCase() : "not available"
    if (value === "connected" || value === "available") {
        return <td style={{ color: "#12D377", fontWeight: 500 }}>{ucfirst(value)}</td>
    } else if (value === "charging") {
        return <td style={{ color: "#0430C0" }}>{ucfirst(value)}</td>
    } else if (value === "no error" || value === "not available" || value === "noerror") {
        return <td style={{ color: "#000" }}>{ucfirst(value)}</td>
    } else if (value === "disconnected") {
        return <td style={{ color: "#F94054", fontWeight: 500 }}>{ucfirst(value)}</td>
    } else if (value === "preparing") {
        return <td style={{ color: "#ECB527" }}>{ucfirst(value)}</td>
    } else {
        return <td style={{ color: "#F94054" }}>{ucfirst(value)}</td>
    }
}

export const blockInvalidChar = e => {
    // Block invalid characters
    if (["e", "E", "+", "-"].includes(e.key)) {
        e.preventDefault()
    }
    // Block arrow down key if value is less than or equal to 0
    if ((e.key === "ArrowDown" || e.key === "Down") && e.target.value <= 0) {
        e.preventDefault()
    }
}

export const onlyInteger = e => {
    blockInvalidChar(e)
    e.key === "." && e.preventDefault()
}

export const acceptNegativeChar = e => ["e", "E", "+"].includes(e.key) && e.preventDefault()

export const getUserInfo = () => {
    return getDecryptedItem("userInfo")
}

export const maskData = value => {
    const substring = value?.substring(value.length - 4, value.length - 1)
    return `****${substring}`
}

export const daysOfWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

export const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
]

export const maxMonthsDay = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

export const getDateFormatted = value => {
    return `${value.getDate().toString().length == 1 ? `0${value.getDate()}` : value.getDate()}/${
        value.getMonth().toString().length == 1 && value.getMonth() != 9
            ? `0${value.getMonth() + 1}`
            : value.getMonth() + 1
    }/${value.getFullYear()}`
}

export const checkDateEqual = (start, end) => {
    return start && end ? getDateFormatted(start) === getDateFormatted(end) : false
}

export const getLocalDateString = date => {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    const year = newDate.getFullYear()
    const month = String(newDate.getMonth() + 1).padStart(2, "0")
    const day = String(newDate.getDate()).padStart(2, "0")
    return `${year}-${month}-${day}`
}

export const initialRangeValue = {
    from: {
        date: "",
        time: "",
        inputDate: "",
    },
    to: {
        date: "",
        time: "",
        inputDate: "",
    },
}

export const isFutureDate = date => isAfter(date, new Date())

export const updateLocalStorageUserInfo = (key, value) => {
    let localStorageData = getDecryptedItem("userInfo")
    localStorageData[key] = value
    saveEncryptedItem("userInfo", localStorageData)
}
export const useDebounce = (value, milliSeconds) => {
    const [debouncedValue, setDebouncedValue] = useState(value)

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, milliSeconds)

        return () => {
            clearTimeout(handler)
        }
    }, [value, milliSeconds])

    return debouncedValue
}

export const extractNumbersFromString = inputString => {
    // Use regular expression to match non-numeric characters and remove them
    return inputString.replace(/\D/g, "")
}

export const monthValues = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
}

export const getMonthObj = (year, month) => new Date(year, month - 1, 1)

export const formatDateToAbbreviatedMonthYear = (inputDate, onlyMonth) => {
    const [year, month] = inputDate.split("-")
    const date = new Date(`${year}-${month}-01`)
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const abbreviatedMonthYear = `${monthNames[date.getMonth()]}-${year.slice(-2)}`
    return onlyMonth ? monthNames[date.getMonth()] : abbreviatedMonthYear
}

export const allMonths = [
    { id: "01", name: "Jan" },
    { id: "02", name: "Feb" },
    { id: "03", name: "Mar" },
    { id: "04", name: "Apr" },
    { id: "05", name: "May" },
    { id: "06", name: "Jun" },
    { id: "07", name: "Jul" },
    { id: "08", name: "Aug" },
    { id: "09", name: "Sep" },
    { id: "10", name: "Oct" },
    { id: "11", name: "Nov" },
    { id: "12", name: "Dec" },
]

export const nthNumber = number => {
    if (number > 3 && number < 21) return "th"
    switch (number % 10) {
        case 1:
            return "st"
        case 2:
            return "nd"
        case 3:
            return "rd"
        default:
            return "th"
    }
}

export const updateQuickFilter = (quickFilter, activeFilters) => {
    let res = false
    Object.entries(quickFilter).every(item => {
        const key = item[0]
        const value = item[1]
        const quickFilterValue = activeFilters?.[key]
        if (quickFilterValue !== value) {
            res = true
            return false
        }
        return true
    })
    return res
}

export const formatData = (data, separator = " ", secondFieldNumber = false, countryCode = "+91") => {
    const array = data?.split(separator)
    const spaceTrim = array?.map(item => item.trim())
    return (
        <div>
            {spaceTrim?.[0]}
            {spaceTrim.length > 1 ? (
                <>
                    <br />
                    {`${secondFieldNumber ? formatNumber(countryCode, spaceTrim?.[1]) : spaceTrim?.[1]}`}
                </>
            ) : null}
        </div>
    )
}

export const initialMonthData = {
    monthId: "",
    monthName: "",
    year: "",
}

const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate()

export const addMonths = (input, months) => {
    const date = new Date(input)
    date.setDate(1)
    date.setMonth(date.getMonth() + months)
    date.setDate(Math.min(input.getDate(), getDaysInMonth(date.getFullYear(), date.getMonth() + 1)))
    return date
}

export function convertToIndianFormat(number) {
    const appendChars = () => {
        const newNumber = number < 0 ? number * -1 : number
        if (newNumber >= 10000000) {
            // 1 crore and above
            return (newNumber / 10000000)?.toFixed(2).replace(/\.00$/, "") + "C"
        } else if (newNumber >= 100000) {
            // 1 lakh and above
            return (newNumber / 100000)?.toFixed(2).replace(/\.00$/, "") + "L"
        } else if (newNumber >= 1000) {
            // 1 thousand and above
            return (newNumber / 1000)?.toFixed(2).replace(/\.00$/, "") + "K"
        } else {
            return newNumber
        }
    }
    if (number < 0) {
        return `-${appendChars()}`
    }
    return appendChars()
}
export const getRangeData = (defaultValue = 3) => {
    const todayDate = new Date()
    todayDate.setHours(0, 0, 0, 0)
    let startDate, endDate

    let yesterday = new Date(todayDate)
    yesterday.setDate(todayDate.getDate() - 1)
    endDate = yesterday
    startDate = addMonths(new Date(), -defaultValue)

    startDate.setHours(0, 0, 0, 0)
    const date = { start: startDate, end: endDate }
    const month = {
        year: startDate.getFullYear(),
        monthId:
            (startDate.getMonth() + 1)?.toString()?.length === 1
                ? `0${startDate.getMonth() + 1}`
                : (startDate.getMonth() + 1)?.toString(),
        monthName: months[startDate.getMonth()]?.slice(0, 3),
    }
    return { type: { value: defaultValue, label: `${defaultValue}M` }, month, date }
}

export const formatAvgUnits = num => {
    if (num) {
        const numArr = num?.toString()?.split(":")
        return `${numArr?.[0]}:${numArr?.[1]}`
    } else return num
}

export const isMac = () => {
    if (navigator.userAgentData) {
        return navigator.userAgentData.platform === "macOS"
    }
    return navigator.userAgent.includes("Mac")
}

export const convertTimeToMinutes = time => {
    if (time) {
        const parts = time?.split(":")
        const hours = parseInt(parts[0], 10)
        const minutes = parseInt(parts[1], 10)

        const totalMinutes = hours * 60 + minutes
        return totalMinutes
    }
    return null
}

export const convertMinutesToTime = minutes => {
    if (!minutes) {
        return "00:01"
    }

    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    const hoursString = hours < 10 ? "0" + hours : hours.toString()
    const minutesString = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes.toString()

    return hoursString + ":" + minutesString
}

export const getSettlementColors = status => {
    if (status === "pending") return "#0430C0"
    if (status === "settled") return "#12D377"
    if (status === "paid") return "#12D377"
    return ""
}

export const getImageURL = (obj, name) => {
    const imagesData = obj ? JSON.parse(obj) : {}
    return imagesData?.[name]
}
export const convertMonthToYearMonth = monthString => {
    if (monthString) {
        const parts = monthString?.split(" ")

        const year = parts[1]
        const monthAbbreviation = parts[0]
        const monthNumber = allMonths.find(item => item?.name === monthAbbreviation)?.id

        return `${year}-${monthNumber}`
    }
    return ""
}

export const getConnectorColor = status => {
    switch (status.toLowerCase()) {
        case "available":
            return "#12D377"
        case "charging":
            return "#0430C0"
        case "preparing":
            return "#ECB527"

        case "unavailable":
            return "#B7BAC6"

        case "faulted":
            return "#F94054"

        default:
            return "#B7BAC6"
    }
}

export const chargerTitle = data => {
    if (data === "csssae") {
        return "CCS"
    }
    if (data === "gbt") {
        return "GB/T"
    }

    return ucfirst(data)
}

export function generateUniqueId() {
    const array = new Uint8Array(16) // Create an array of 16 random bytes
    window.crypto.getRandomValues(array) // Fill the array with cryptographically secure random values

    // Convert the byte array to a string
    const randomString = Array.from(array, byte => byte.toString(36))
        .join("")
        .substring(0, 16)
    const uniqueId = "ID_" + randomString + "_" + Date.now()
    return uniqueId
}

export const minimumYear = 2020

export const getYearsData = currentYear => {
    return Array.from({ length: currentYear - minimumYear + 1 }, (_, index) => currentYear - index)
}

export const identifyCountryByTimezone = () => {
    const timeZone = Intl?.DateTimeFormat()?.resolvedOptions().timeZone

    // Check for Nepal
    if (timeZone === "Asia/Kathmandu" || timeZone === "Asia/Katmandu") {
        // Nepal country Id 153
        return 153
    }

    // India country Id 101
    return 101
}

export const visibilityStatusDropdown = [
    { id: 1, name: "Visible" },
    { id: 0, name: "Not Visible" },
]

export const formatToTwoDecimal = val => {
    if (typeof val === "number") {
        return val.toFixed(2)
    }
    if (typeof val === "string") {
        return Number(val).toFixed(2)
    }
}

export const convertDateTimeToUTC = date => {
    if (date) {
        const dateStr = new Date(date)
        const utcDate = dateStr.toISOString()
        return utcDate
    }
    return null
}

export const breakLine = (text, maxLength = 10) => {
    if (!text) return ""
    const words = text.split(" ")
    return words
        .map(word => {
            if (word.length > maxLength) {
                const regex = new RegExp(`.{1,${maxLength}}`, "g")
                return word.match(regex).join("\n")
            }
            return word
        })
        .join(" ")
}

export const getChargerStatusColor = value => {
    switch (value?.toLowerCase()) {
        case "expired":
        case "0":
        case "decommissioned":
        case "disabled":
            return "red"
        case "active":
        case "1":
        case "commissioned":
            return "green"
        case "onboarded":
            return "blue"
        case "enabled":
            return "green"
        default:
            return ""
    }
}

export const blockInvalidCharPaste = e => {
    const paste = (e.clipboardData || window.clipboardData).getData("text")
    if (isNaN(paste)) {
        e.preventDefault()
    }
}

export const setLicenseCount = count => {
    localStorage.setItem("licenseCount", count)
}

export const getLicenseCount = () => {
    const licenseCount = localStorage.getItem("licenseCount")
    return licenseCount
}

export const clearLicenseCount = () => {
    localStorage.removeItem("licenseCount")
}

export const renderAutocompletePhoneOption = (option, countryCode) => {
    if (!option) return ""
    const phone = option?.phone?.toString()
    const phoneNumber = phone
        ? `+${countryCode}-${phone?.substring(phone.length > 10 ? countryCode?.toString()?.length : 0)}`
        : ""
    return `${option?.name} ${phoneNumber ? `(${phoneNumber})` : ""} `
}

export const formatApexDateRange = data => {
    if (data?.type?.label === "YTD" || data?.type?.label === "All") return ""

    const startDate = new Date(data.date.start)
    const endDate = new Date(data.date.end)

    const startDay = startDate.getDate()
    const startMonth = startDate.toLocaleString("default", { month: "short" })
    const startYear = startDate.getFullYear()

    const endDay = endDate.getDate()
    const endMonth = endDate.toLocaleString("default", { month: "short" })
    const endYear = endDate.getFullYear()

    return ` ${startDay} ${startMonth}, ${startYear} - ${endDay} ${endMonth}, ${endYear}`
}

export const getBookingIdsArr = string => {
    const arr = string?.split("|")?.[0]?.match(/\d+/g).map(Number)
    return arr
}
