import { appImages } from "../cdnImages.js"
import React from "react"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"

import "../css/Common.css"

const InternalError = () => {
    const navigate = useNavigate()
    return (
        <div className="five_hundred_page_container ">
            <div className="five_hundred_page_img_container">
                <img src={appImages?.["500_img"]} />
            </div>
            <div>
                <h1 className="five_hundred_page_title">
                    There is always time for a coffee break.
                    <br />
                    We will be back by the time you finish your coffee.
                </h1>
                <div className="text_center">
                    <Button
                        onClick={() => navigate(-1)}
                        type="button"
                        variant="contained"
                        className="five_hundred_page_button">
                        Try Again
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default InternalError
